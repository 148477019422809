import { styled } from "@mui/material";
import { Box } from "@mui/system";
import SubCategoryForm from "./SubCategoryForm";
import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import { subCategoryCreate } from "services/api/sub-category";
import SimpleCard from "ui-component/SimpleCard";
const SubCategoryCreate = ({type}) => {
    const title = 'SubCategory';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const currentSubCategory = { name: "", image: "", type: type , parent_id: "" }

    const handleSubmit = (state) => { 
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("image", state.image)
        formData.append("type", state.type)
        formData.append("parent_id", state.parent_id)
        
        return subCategoryCreate(formData);
    };


    return (
        // <h1>asdasd</h1>
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "SubCategory Create" }]} />
            </Box>
             <SimpleCard title="SubCategory Form">
                 <SubCategoryForm handleSubmit={handleSubmit} tableData={currentSubCategory} type={type}/>
             </SimpleCard>
         </Container>
    );
};  
export default SubCategoryCreate;