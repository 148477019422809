// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const ready_form = {
    id: 'ready_form',
    title: 'Ready Form',
    type: 'group',
    children: [
        {
            id: 'ready_form_category',
            title: 'Categories',
            type: 'item',
            url: '/ready-form/categories',
            icon: icons.IconTypography,
            breadcrumbs: false
        },
        {
            id: 'ready_form_sub_category',
            title: 'Sub Categories',
            type: 'item',
            url: '/ready-form/sub-categories',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'ready_form_form',
            title: 'Form',
            type: 'item',
            url: '/ready-form/form',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        
    ]
};

export default ready_form;
