import SUBCATEGORY_ACTION_TYPES from './subCategory.types';

export const setReadyFormSubCategoryList = (subcategory) => {
  return { type: SUBCATEGORY_ACTION_TYPES.LOAD_READY_FORM_SUBCATEGORIES, payload: subcategory };
};

export const setIPrintSubCategoryList = (subcategory) => {
  return { type: SUBCATEGORY_ACTION_TYPES.LOAD_I_PRINT_SUBCATEGORIES, payload: subcategory };
};

export const setPaymentServiceSubCategoryList = (subcategory) => {
  return { type: SUBCATEGORY_ACTION_TYPES.LOAD_PAYMENT_SERVICE_SUBCATEGORIES, payload: subcategory };
};
