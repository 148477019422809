import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import SubCategory from "./SubCategory";
import SubCategoryCreate from "./SubCategoryCreate";
import SubCategoryUpdate from "./SubCategoryUpdate";

 

const SubCategoryRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/ready-form/sub-categories",
            element:<SubCategory type='RF' path='/ready-form'/>
        },
        {
            path:"/i-print/sub-categories",
            element:<SubCategory type='IP' path='/i-print'/>
        },
        {
            path:"/payment-center/sub-categories",
            element:<SubCategory type='PS' path='/payment-center'/>
        },
        {
            path:"/ready-form/sub-categories/create",
            element:<SubCategoryCreate type='RF'/>
        }, 
        {
            path:"/i-print/sub-categories/create",
            element:<SubCategoryCreate type='IP'/>
        }, 
        {
            path:"/payment-center/sub-categories/create",
            element:<SubCategoryCreate type='PS'/>
        }, 
        {
            path:"/ready-form/sub-categories/edit/:subCategoryId",
            element:<SubCategoryUpdate type='RF'/>
        }, 
        {
            path:"/i-print/sub-categories/edit/:subCategoryId",
            element:<SubCategoryUpdate type='IP'/>
        }, 
        {
            path:"/payment-center/sub-categories/edit/:subCategoryId",
            element:<SubCategoryUpdate type='PS'/>
        }, 
    ]
}

export default SubCategoryRoutes;