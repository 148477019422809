import READYFORM_ACTION_TYPES from './readyForm.types';

export const READYFORM_INITIAL_STATE = { 
  readyFormLists: [], 
};

export const ReadyFormReducer = (state = READYFORM_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) { 
    case READYFORM_ACTION_TYPES.LOAD_READYFORMS: {
      return { ...state, readyFormLists: payload };
    }
    default:
      return state;
  }
};
