import { useSelect } from "@mui/base";
import { IconButton, Avatar } from "@mui/material";
import useAlert from "hooks/useAlert"
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";
import { isObjectEmpty } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { categoryDelete, categoryList, categoryUpdateStatus } from "services/api/category";
import { setIPrintCategoryList, setPaymentServiceCategoryList, setReadyFormCategoryList } from "store/category/category.action";
import { selectCategory } from "store/category/category.selector";
const Category = ({ type }) => {
  
  let title = "category";
  if (type == 'RF') {
    title = 'Ready Form Category';
  } else if (type == 'IP') {
    title = 'I Print Category';
  } else if (type == 'PS') {
    title = 'Payment Center Category';
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(true);
  const { openSnackbar } = useSnackbar();

  const setData = () => { 
     categoryList(type)
      .then((res) => {
        if(type == 'RF'){
          dispatch(setReadyFormCategoryList(res.data.result.data));
        } else if(type == 'IP'){
          
          dispatch(setIPrintCategoryList(res.data.result.data));
        } else if(type == 'PS') {
          dispatch(setPaymentServiceCategoryList(res.data.result.data));
        }
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      }).finally(()=>{
        setLoading(false);
      });
       
  };

  useEffect(() => {
    setLoading(true);
    setData();
  }, [type]);

  const deleteHandler = (id) => {
    setLoading(false);
    categoryDelete(id)
      .then((res) => {
        setData();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };

  const handleEdit = (id, row) => {
    navigate("edit/" + row.rowData[0]);
  };

  const handleStatusUpdate = (id, value) => {
    categoryUpdateStatus(id)
      .then((res) => {
        setData();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar("error", error.data.message);
      });
  };

  const handleDelete = (event, row) => {
    updateState(`${title} Delete`, `Are you sure you want to delete ${title}`, () => deleteHandler(row["rowData"][0]));
  };

  const { readyFormCategories, iPrintCategories, paymentServiceCategories } = useSelector(selectCategory);

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return (
            <Avatar variant="square" alt="John Doe" src={valueData} />
            
          );
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (valueData, tableMeta) => {
          let value = valueData == "Active" ? 1 : 0;
          return (
            <SimpleSwitch
              id={tableMeta["rowData"][0]}
              value={value}
              changeHandler={(id, value) => handleStatusUpdate(id, value)}
            />
          );
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleEdit(e, tableMeta);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleDelete(e, tableMeta);
                }}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  function mapCategories(categories) {
    return categories.map((item, index) => ([
      item.id,
      index + 1,
      item.image,
      item.name,
      item.status == 1 ? "Active" : "Inactive",
      item.created_at,
    ]));
  }
  
  let data = [];
  
  if (type == 'RF') {
    data = isObjectEmpty(readyFormCategories) ? [] : mapCategories(readyFormCategories);
  } else if (type == 'IP') {
    data = isObjectEmpty(iPrintCategories) ? [] : mapCategories(iPrintCategories);
  } else if (type == 'PS') {
    data = isObjectEmpty(paymentServiceCategories) ? [] : mapCategories(paymentServiceCategories);
  }

  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };

  return (
    <Datatable
      title={title}
      data={data}
      columns={columns}
      options={options}
      isLoading={loading}
      addHandler={() => navigate("create")}
    />);
};
export default Category;
