import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import Admin from "./Admin";
import AdminCreate from "./AdminCreate";
import AdminUpdate from "./AdminUpdate";

 

const AdminRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/admins",
            element:<Admin/>
        },
        {
            path:"/admins/create",
            element:<AdminCreate/>
        },
        {
            path:"/admins/edit/:adminId",
            element:<AdminUpdate/>
        },
    ]
}

export default AdminRoutes;