import { useSelect } from "@mui/base";
import { Button,Icon,IconButton } from "@mui/material";
import useAlert from "hooks/useAlert" 
import useSnackbar from "hooks/useSnackbar";
import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { subCategoryDelete, subCategoryList, subCategoryUpdateStatus } from "services/api/sub-category";
import { setIPrintSubCategoryList, setPaymentServiceSubCategoryList, setReadyFormSubCategoryList, setSubCategoryList } from "store/subCategory/subCategory.action";
import { selectSubCategory } from "store/subCategory/subCategory.selector";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";

import { isObjectEmpty } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from "hooks/useAuth";
const SubCategory = ({type,path}) => {
  const title = "SubCategory";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setSubCategory = () => {
    subCategoryList(`filter[type]=${type}`)
      .then((res) => {
        if(type == 'RF'){
          dispatch(setReadyFormSubCategoryList(res.data.result.data));
        } else if(type == 'IP'){
          
          dispatch(setIPrintSubCategoryList(res.data.result.data));
        } else if(type == 'PS') {
          dispatch(setPaymentServiceSubCategoryList(res.data.result.data));
        }
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      }).finally(()=>{
        setLoading(false);
      });
  };
const {user}=useAuth();
  
  useEffect(() => {
    setLoading(true);
    setSubCategory(); 
  }, [type]);

  const deleteHandler = (id) => {
    setLoading(true);
    subCategoryDelete(id)
      .then((res) => {
        setSubCategory();
        openSnackbar("success", res.data.message);
      })  
      .catch((error) => { 
        openSnackbar("error", error.response.data.message);
      });
  };

  const handleEdit = (id, row) => {
    navigate(path+"/sub-categories/edit/" + row.rowData[0]);
  };

  const handleStatusUpdate = (id, value) => {
    subCategoryUpdateStatus(id)
      .then((res) => {
        setSubCategory();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar("error", error.data.message);
      });
  };

  const handleDelete = (event, row) => {
    updateState("SubCategory Delete",'Are you sure you want to delete SubCategory',() => deleteHandler(row["rowData"][0]));
  };

  const { readyFormSubCategories, iPrintSubCategories, paymentServiceSubCategories } = useSelector(selectSubCategory);
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "parent_category",
      label: "Parent Category",
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        customBodyRender: (valueData, tableMeta) => {    
          return ( 
            <SimpleSwitch
              id={tableMeta["rowData"][0]}
              value={valueData}
              changeHandler={(id, valueData) => handleStatusUpdate(id, valueData)}
            />
          );
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleEdit(e, tableMeta);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleDelete(e, tableMeta);
                }}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];


  function mapCategories(subcategories) { 
    return subcategories.map((item, index) => ([
          item.id,
          (index += 1),
          item.name,
          item.category?.name,
          item.status,
          item.created_at,
        ]));
    };

    let data = [];
  

      if (type == 'RF') {
        data = isObjectEmpty(readyFormSubCategories) ? [] : mapCategories(readyFormSubCategories);
      } else if (type == 'IP') {
        data = isObjectEmpty(iPrintSubCategories) ? [] : mapCategories(iPrintSubCategories);
      } else if (type == 'PS') {
        data = isObjectEmpty(paymentServiceSubCategories) ? [] : mapCategories(paymentServiceSubCategories);
      }
    
  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  return (
  <Datatable
    title={title}
    data={data}
    columns={columns}
    options={options}
    isLoading={loading}
    addHandler={() => navigate('create')}
  />);
};
export default SubCategory;
