
import React, { useEffect, useState } from 'react'
import FieldPopUp from './FieldPopUp'
import { useParams } from 'react-router-dom';  
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import FieldTable from './FieldTable';
import ImageWithText from 'ui-component/ImageWithText';
import { dynamicFormById } from 'services/api/dynamic-form';

function DynamincFormFieldUpdate() {

    const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'flex-start' }));

    const ContentBox = styled(Box)(() => ({
        height: '100%',
        width: '371px',
        height: '670px',
        padding: '32px',
        position: 'relative',
        background: 'rgba(0, 0, 0, 0.01)',
    }));

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [forms, setForms] = useState({});
    const [position, setPosition] = useState({ x: 0, y: 0, w: 0, h: 0 });

    const { formId } = useParams();


    useEffect(() => {
        setLoading(true);

        try {
            dynamicFormById(formId).then((res) => { 
                setForms({ forms, sub_category_id: res.data.result.sub_category.id, sub_category_name: res.data.result.sub_category.name, ...res.data.result });

                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
            // setSubCategory(error);
        }
    }, [])

    return (
        <div>
            <FlexBox >
                <ImageWithText setOpen={setOpen} forms={forms} setPosition = {setPosition} /> 
                <FieldTable form={forms} />
            </FlexBox>
            <FieldPopUp open={open} setOpen={setOpen} formId={formId} position={position} />
        </div>
    )
}

export default DynamincFormFieldUpdate
