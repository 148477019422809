import CATEGORY_ACTION_TYPES from './category.types';

export const CATEGORY_INITIAL_STATE = {
  readyFormCategories: [],
  iPrintCategories: [],
  paymentServiceCategories: [],
};

export const CategoryReducer = (state = CATEGORY_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_ACTION_TYPES.LOAD_READY_FORM_CATEGORIES: {
      return { ...state, readyFormCategories: payload };
    }
    case CATEGORY_ACTION_TYPES.LOAD_I_PRINT_CATEGORIES: {
      return { ...state, iPrintCategories: payload };
    }
    case CATEGORY_ACTION_TYPES.LOAD_PAYMENT_SERVICE_CATEGORIES: {
      return { ...state, paymentServiceCategories: payload };
    }
    default:
      return state;
  }
};
