import { createSelector } from "reselect";

const selectDynamicFormReducer = (state) => {
  return state.dynamicForm.dynamicFormLists
};

export const selectDynamicForm = createSelector(
  [selectDynamicFormReducer],
  (state) => {
    return state
  }
);