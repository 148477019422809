import { styled } from "@mui/material";
import { Box } from "@mui/system";
import DynamicFormForm from "./DynamicFormForm";
import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import { dynamicFormCreate } from "services/api/dynamic-form";
import SimpleCard from "ui-component/SimpleCard";
const DynamicFormCreate = () => {
    const title = 'DynamicForm';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const currentDynamicForm = { name: "", category_id: "", file: "" , price_color: "",price_black_and_white:"" }

    const handleSubmit = (state) => { 
        const formData = new FormData();
        console.log(state);
        formData.append("name", state.name)
        formData.append("category_id", state.category_id)
        formData.append("file", state.file)
        formData.append("price_color", state.price_color)
        formData.append("price_black_and_white", state.price_black_and_white)
        
        return dynamicFormCreate(formData);
    };


    return ( 
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "DynamicForm Create" }]} />
            </Box>
             <SimpleCard title="DynamicForm Form">
                 <DynamicFormForm handleSubmit={handleSubmit} tableData={currentDynamicForm}/>
             </SimpleCard>
         </Container>
    );
};  
export default DynamicFormCreate;