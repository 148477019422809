import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import Category from "./Category";
import CategoryCreate from "./CategoryCreate";
import CategoryUpdate from "./CategoryUpdate";

 

const CategoryRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/ready-form/categories",
            element:<Category type='RF'/>
        },
        {
            path:"/ready-form/categories/create",
            element:<CategoryCreate type='RF'/>
        },
        {
            path:"/ready-form/categories/edit/:categoryId",
            element:<CategoryUpdate type='RF'/>
        },
        {
            path:"/i-print/categories",
            element:<Category type='IP'/>
        },
        {
            path:"/i-print/categories/create",
            element:<CategoryCreate type='IP'/>
        },
        {
            path:"/i-print/categories/edit/:categoryId",
            element:<CategoryUpdate type='IP'/>
        },
        {
            path:"/payment-center/categories",
            element:<Category type='PS'/>
        },
        {
            path:"/payment-center/categories/create",
            element:<CategoryCreate type='PS'/>
        },
        {
            path:"/payment-center/categories/edit/:categoryId",
            element:<CategoryUpdate type='PS'/>
        }
    ]
}

export default CategoryRoutes;