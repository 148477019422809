import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/helper";
import Breadcrumb from "ui-component/Breadcrumb";
import SimpleCard from "ui-component/SimpleCard";
import { selectCategory } from "store/category/category.selector";
import { categoryUpdate } from "services/api/category";
import CategoryForm from "./CategoryForm";

const CategoryUpdate = ({type}) => {

  let url = "categories";
  let title = "Categories";
  if (type == 'RF') {
      url = 'ready-form/categories';
      title = 'Ready Form Categories';
  } else if (type == 'IP') {
      url = 'I Print Categories';
  } else if (type == 'PS') {
      url = 'Payment Center Categories';
  }

  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const dispatch = useDispatch();
  const { categoryId } = useParams();

  const { readyFormCategories, iPrintCategories, paymentServiceCategories } = useSelector(selectCategory);

  let currentCategory = [];
  
  if (type == 'RF') {
    currentCategory = readyFormCategories.filter((item) => item.id == categoryId);
    
  } else if (type == 'IP') {
    currentCategory = iPrintCategories.filter((item) => item.id == categoryId);
  } else if (type == 'PS') {
    currentCategory = paymentServiceCategories.filter((item) => item.id == categoryId);
  }


  const handleSubmit = (state) => {
    const formData = new FormData();
        formData.append("name", state.name)
        formData.append("image", state.image)

    return categoryUpdate(categoryId, formData);
  };

  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + url }, { name: `${title} Update` }]} />

      </Box>
      <SimpleCard title="Category Form">
        <CategoryForm
          type={url}
          handleSubmit={handleSubmit}
          tableData={{ ...currentCategory[0] }}
        />
      </SimpleCard>
    </Container>
  );
};

export default CategoryUpdate;
