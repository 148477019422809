import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux"; 
import SettingForm from "./SettingForm";
import { capitalizeFirstLetter } from "utils/helper";
import { settingById, settingUpdate } from "services/api/setting";
import Breadcrumb from "ui-component/Breadcrumb";
import { selectSetting } from "store/setting/setting.selector";
import SimpleCard from "ui-component/SimpleCard";
import { useEffect } from "react";
import { setSettingList } from "store/setting/setting.action";
import useSnackbar from "hooks/useSnackbar";

const SettingUpdate = () => {
  const title = "settings";
  const { openSnackbar } = useSnackbar();
  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const data =()=>{
    settingById(1).then((res)=>{
      dispatch(setSettingList(res.data.result))
    })
    .catch((error) => {
      openSnackbar("error", error.data.message);
    });
  }
  const dispatch = useDispatch();
  useEffect(()=>{
    data();
  },[])

 
  const handleSubmit = (state) => {
    console.log(state);
    const formData = {
      price_color: state.price_color,
      price_black_and_white: state.price_black_and_white, 
    };

    settingUpdate(1, formData).then((res)=>{
      console.log(res.data.result);
      dispatch(setSettingList(res.data.result))
      openSnackbar("success", res.data.message);
    })
    .catch((error) => {
      openSnackbar("error", error.data.message);
    }); 
  }; 

  const currentSetting = useSelector(selectSetting);

  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            { name: capitalizeFirstLetter(title), path: "/" + title },
            { name: "Setting Update" },
          ]}
        />
      </Box>
      <SimpleCard title="Setting Form">
        <SettingForm
          handleSubmit={handleSubmit}
          tableData={{ ...currentSetting }}
        />
      </SimpleCard>
    </Container>
  );
};

export default SettingUpdate;
