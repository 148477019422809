import { LoadingButton } from "@mui/lab";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { categoryList } from "services/api/category";
import {
  setIPrintCategoryList,
  setPaymentServiceCategoryList,
  setReadyFormCategoryList,
} from "store/category/category.action";
import { selectCategory } from "store/category/category.selector";
import { isObjectEmpty } from "utils/helper";
import FileInput from "ui-component/FileInput";
const SubCategoryForm = ({ handleSubmit, tableData, type }) => {
  const [state, setState] = useState({ ...tableData });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const { readyFormCategories, iPrintCategories, paymentServiceCategories } =
    useSelector(selectCategory);

  const setData = () => {  
    categoryList(type)
      .then((res) => {
        if (type === "RF") {
          dispatch(setReadyFormCategoryList(res.data.result.data));
        } else if (type === "IP") {
          dispatch(setIPrintCategoryList(res.data.result.data));
        } else if (type === "PS") {
          dispatch(setPaymentServiceCategoryList(res.data.result.data));
        }
        return res.data.result.data;
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
    return [];
  };
  var category;
  if (type === "RF") {
    category = isObjectEmpty(readyFormCategories)
      ? setData()
      : readyFormCategories;
  } else if (type === "IP") {
    category = isObjectEmpty(iPrintCategories) ? setData() : iPrintCategories;
  } else if (type === "PS") {
    category = isObjectEmpty(paymentServiceCategories)
      ? setData()
      : paymentServiceCategories;
  }
  const handleFormSubmit = async (values, { setFieldError }) => {
    setLoading(true);
    await handleSubmit({
      ...values,
      image: state.file,
    })
      .then((res) => {
        openSnackbar("success", res.data.message);
        setLoading(false);
        navigate(-1);
      })
      .catch((e) => {
        if (e.response.status === 422) {
          console.log(e.response.data.result);
          Object.entries(e.response.data.result).forEach(([key, value]) =>
            setFieldError(key, value)
          );
        }
        setLoading(false);
      });
  };
  const handleFileChange = (index, file) => {
    setState({ ...state, file: file?.files[0] });
    console.log(state);
  };
  return (
    <div>
      <Formik onSubmit={handleFormSubmit} initialValues={state}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="name"
                  id="name"
                  value={values.name || ""}
                  onChange={handleChange}
                  label="Name"
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <Autocomplete
                  options={category}
                  disableClearable
                  defaultValue={state.category}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setFieldValue("parent_id", newValue.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  id="controllable-category-state"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category Name"
                      name="category"
                      value={state.category || ""}
                      variant="outlined"
                      onChange={handleChange}
                      helperText={touched.parent_id && errors.parent_id}
                      error={Boolean(touched.parent_id && errors.parent_id)}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <FileInput
                  name="image"
                  url={values.image}
                  handleChange={handleFileChange}
                  fileName="image"
                  label="image"
                  helperText={touched.image && errors.image}
                  error={Boolean(touched.image && errors.image)}
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              color="primary"
              loading={loading}
              variant="contained"
              sx={{ my: 4 }}
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SubCategoryForm;
