import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import { AdminReducer } from './admin/admin.reducer';
import { CategoryReducer } from './category/category.reducer';
import { SubCategoryReducer } from './subCategory/subCategory.reducer';
import { ReadyFormReducer } from './readyForm/readyForm.reducer';
import { LinkReducer } from './link/link.reducer';
import { DynamicformReducer } from './dynamicForm/dynamicForm.reducer';
import { SettingReducer } from './setting/setting.reducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    admin:AdminReducer,
    category:CategoryReducer,
    subCategory:SubCategoryReducer,
    readyForm:ReadyFormReducer,
    dynamicForm:DynamicformReducer,
    link:LinkReducer,
    setting:SettingReducer,
});

export default reducer;
