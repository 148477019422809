import { useSelect } from "@mui/base";
import { Button,Icon,IconButton } from "@mui/material";
import useAlert from "hooks/useAlert" 
import useSnackbar from "hooks/useSnackbar";
import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { readyFormDelete, readyFormList, readyFormUpdateStatus } from "services/api/ready-form";
import { setReadyFormList } from "store/readyForm/readyForm.action";
import { selectReadyForm } from "store/readyForm/readyForm.selector";
import SimpleSwitch from "ui-component/SimpleSwitch";
import Datatable from "ui-component/tables/Datatable";

import { isObjectEmpty } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useAuth from "hooks/useAuth";
const ReadyForm = () => {
  const title = "ReadyForm";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setReadyForm = () => {
    readyFormList()
      .then((res) => { 
          dispatch(setReadyFormList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar("error", error.data.message);
      }).finally(()=>{
        setLoading(false);
      });
  }; 
  
  useEffect(() => {
    setLoading(true);
    setReadyForm(); 
  }, []);

  const deleteHandler = (id) => {
    setLoading(true);
    readyFormDelete(id)
      .then((res) => {
        setReadyForm();
        openSnackbar("success", res.data.message);
      })  
      .catch((error) => {
        openSnackbar("error", error.data.message);
      });
  };

  const handleEdit = (id, row) => {
    navigate("/ready-form/form/edit/" + row.rowData[0]);
  };

  const handleStatusUpdate = (id, value) => {
    readyFormUpdateStatus(id)
      .then((res) => {
        setReadyForm();
        openSnackbar("success", res.data.message);
      })
      .catch((error) => {
        console.log(error);
        openSnackbar("error", error.data.message);
      });
  };

  const handleDelete = (event, row) => {
    updateState("ReadyForm Delete",'Are you sure you want to delete ReadyForm',() => deleteHandler(row["rowData"][0]));
  };

  const readyForm = useSelector(selectReadyForm); 
  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: "Sr.no.",
      options: {
        display: true,
        download: true,
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name",
    },
    {
      name: "sub_category_name",
      label: "Sub Category",
    },
    {
      name: "is_active",
      label: "Status",
      options: {
        customBodyRender: (valueData, tableMeta) => {    
          return ( 
            <SimpleSwitch
              id={tableMeta["rowData"][0]}
              value={valueData}
              changeHandler={(id, valueData) => handleStatusUpdate(id, valueData)}
            />
          );
        },
        filter: true,
        filterType: "dropdown",
      },
    },
    {
      label: "Create Date",
      options: {
        filter: false,
      },
    },
    {
      name: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <IconButton
                onClick={(e) => {
                  handleEdit(e, tableMeta);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
              <IconButton
                onClick={(e) => {
                  handleDelete(e, tableMeta);
                }}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const data =  isObjectEmpty(readyForm)
  ? []
  : readyForm.map((item, index) => {
      return [
        item.id,
        (index += 1),
        item.name,
        item.category?.name,
        item.status,
        item.created_at,
      ];
    })
    
  const options = {
    filterType: "textField",
    fixedHeader: true,
    tableBodyHeight: "400px",
  };
  return (
  <Datatable
    title={title}
    data={data}
    columns={columns}
    options={options}
    isLoading={loading}
    addHandler={() => navigate('create')}
  />);
};
export default ReadyForm;
