import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout"; 
import ReadyForm from "./ReadyForm";
import ReadyFormCreate from "./ReadyFormCreate";
import ReadyFormUpdate from "./ReadyFormUpdate";

 

const ReadyFormRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/ready-form/form",
            element:<ReadyForm/>
        },
        {
            path:"/ready-form/form/create",
            element:<ReadyFormCreate/>
        },
        {
            path:"/ready-form/form/edit/:formId",
            element:<ReadyFormUpdate/>
        },
    ]
}

export default ReadyFormRoutes;