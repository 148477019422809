// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const payment_center = {
    id: 'payment_center',
    title: 'Payment Center',
    type: 'group',
    children: [
        {
            id: 'payment_center_category',
            title: 'Categories',
            type: 'item',
            url: '/payment-center/categories',
            icon: icons.IconTypography,
            breadcrumbs: false
        },
        {
            id: 'payment_center_sub_category',
            title: 'Sub Categories',
            type: 'item',
            url: '/payment-center/sub-categories',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'link',
            title: 'Links',
            type: 'item',
            url: '/payment-center/links',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        
    ]
};

export default payment_center;
