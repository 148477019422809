import { createSelector } from "reselect";

const selectCategoryReducer = (state) => {
  return state.category
};

export const selectCategory = createSelector(
  [selectCategoryReducer],
  (state) => {
    return state
  }
);