import { createSelector } from "reselect";

const selectSettingReducer = (state) => {
  return state.setting.settingLists
};

export const selectSetting = createSelector(
  [selectSettingReducer],
  (state) => {
    return state
  }
);