// import { flat } from 'app/utils/utils';
import useAuth from 'hooks/useAuth';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Loader from 'ui-component/Loader';

const GuestGuard = ({ children }) => {
  let {
    isAuthenticated,
    isInitialised,
    user
  } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  console.log('authenticated: '+ isAuthenticated)

  if (isInitialised) {
    if (isAuthenticated) { 
  
        return <Navigate replace to="dashboard/default" state={{ from: pathname }} />;
    } else {
        return <>{children}</>;
    }
  } else {
    // Authentication context is not yet initialized, display a loading indicator.
    return <Loader />;
  }
};

export default GuestGuard;
