import { LoadingButton } from "@mui/lab";
import {
    Grid,
    TextField,
} from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FileInput from "ui-component/FileInput";


const CategoryForm = ({ type, handleSubmit, tableData }) => {
    const [state, setState] = useState({ ...tableData });

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbar();

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleFileChange = (index, file) => {
        setState({ ...state, 'image': file.files[0] });
    };

    const handleFormSubmit = async (values, { setFieldError }) => {
        setLoading(true);
        await handleSubmit({name: values.name ,image: state.image}).then((res) => {
            openSnackbar("success", res.data.message)
            setLoading(false);
            navigate(-1);
        }).catch((e) => {
            if (e.response.status == 422) {
                console.log(e.response.data.result);
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value)

                );

            }
            setLoading(false);
        });
    };

    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={state}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    label="Name"
                                    helperText={touched.name && errors.name}
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                                <FileInput
                                    url={values.image}
                                    handleChange={handleFileChange}
                                    fileName="image"
                                    label="Image"
                                    helperText={touched.image && errors.image}
                                    error={Boolean(touched.image && errors.image)}
                                />
                            </Grid>

                        </Grid>

                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={loading}
                            variant="contained"
                            sx={{ my: 4 }}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default CategoryForm;
