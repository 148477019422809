import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formFieldCreate } from "services/api/form-field";

export default function FieldPopUp({ open, setOpen, formId, position } = open) {
  const navigate = useNavigate();

  const [state, setState] = useState({
    label: "",
    isRequired: 0,
    font_size: 15,
    formId: formId,
  });
  const [fields, setFields] = useState([{ value: "" }]);

  // handle field value change
  const handleDynamicFieldChange = (index, event) => {
    const values = [...fields];
    values[index].value = event.target.value;
    setFields(values);
  };

  useEffect(() => {
    handleRemoveField(0);
  }, []);

  // handle adding a new field
  const handleAddField = () => {
    console.log(fields);
    const values = [...fields];
    values.push({ value: "" });
    console.log(values);
    setFields(values);
  };

  // handle removing a field
  const handleRemoveField = (index) => {
    const values = [...fields];
    values.splice(index, 1);
    setFields(values);
  };

  // render form fields
  const renderFields = () => {
    return fields.map((field, index) => (
      <div key={index}>
        <TextField
          autoFocus
          margin="dense"
          id={"field_" + index}
          name={"field_" + index}
          value={field.value}
          onChange={(event) => handleDynamicFieldChange(index, event)}
          label="Options"
          // fullWidth
        />
        <IconButton
          sx={{
            marginTop: 2,
          }}
          onClick={() => handleRemoveField(index)}
          aria-label="delete"
        >
          <DeleteIcon />
        </IconButton>
      </div>
    ));
  };

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSubmit() {
    // console.log(state);
    // console.log(fields);

    const options = [];
    fields.map((item) => options.push(item.value));
    // console.log(options);

    const formData = {
      label: state.label,
      form_id: state.formId,
      is_required: state.isRequired,
      field_type: state.field_type,
      position: {
          font_size: state.font_size,
        x: position.x,
        y: position.y,
        w: position.w,
        h: position.h,
      },
      options: options,
    };
    // console.log(formData);
    formFieldCreate(formData)
      .then((res) => {
        window.location.reload(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function handleFieldChange(event) {
    if (event.target.name == "isRequired") {
      setState({ ...state, isRequired: state.isRequired == 0 ? 1 : 0 });
    } else {
      setState({ ...state, [event.target.name]: event.target.value });
    }
  }

  return (
    <Box>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create New Form Fields</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <TextField
                autoFocus
                margin="dense"
                id="label"
                name="label"
                value={state.label}
                onChange={(e) => handleFieldChange(e)}
                label="Label"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <TextField
                autoFocus
                type="number"
                margin="dense"
                id="font_size"
                name="font_size"
                value={state.font_size}
                onChange={(e) => handleFieldChange(e)}
                label="Font Size"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <FormControlLabel
                label="Is Required"
                control={
                  <Checkbox
                    label="Is Required"
                    id="isRequired"
                    name="isRequired"
                    value={state.isRequired}
                    onChange={(e) => {
                      handleFieldChange(e);
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="field_type_select">Field Type</InputLabel>
                <Select
                  labelId="field_type_select"
                  id="field_type"
                  name="field_type"
                  value={state.field_type}
                  label="Field Type"
                  onChange={(e) => handleFieldChange(e)}
                >
                  <MenuItem value={"Text Field"}>Text Field</MenuItem>
                  <MenuItem value={"Drop Down"}>Dropdown</MenuItem>
                  {/* <MenuItem value={"Image"}>Image</MenuItem> */}
                  {/* <MenuItem value={"Signature"}>Signature</MenuItem> */}
                  <MenuItem value={"Checkbox"}>CheckBox</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {state.field_type == "Drop Down" && (
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
                <Typography variant="subtitle1" component="h5">
                  Dropdown Options
                </Typography>
                {renderFields()}
                <IconButton onClick={handleAddField} aria-label="delete">
                  <Icon color="primary">add_circle</Icon>
                </IconButton>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Add Field
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
