import { createSelector } from "reselect";

const selectLinkReducer = (state) => {
  return state.link.linkLists
};

export const selectLink = createSelector(
  [selectLinkReducer],
  (state) => {
    return state
  }
);