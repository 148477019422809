import axios from "axios";
import config from "config";
const axiosClient = axios.create({
  baseURL: config.baseUrl + config.apiVersion1 + "/admin/",
  headers: {
    Accept: "application/json",
    "Content-Type":
      "multipart/form-data; boundary=<calculated when request is sent>",
  },
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    if (res.status == 401) {
      return (window.location.href = "/login");
    }
    console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

export default axiosClient;
