import DYNAMICFORM_ACTION_TYPES from './dynamicForm.types';

export const DYNAMICFORM_INITIAL_STATE = { 
  dynamicFormLists: [], 
};

export const DynamicformReducer = (state = DYNAMICFORM_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) { 
    case DYNAMICFORM_ACTION_TYPES.LOAD_DYNAMICFORMS: {
      return { ...state, dynamicFormLists: payload };
    }
    default:
      return state;
  }
};
