import CATEGORY_ACTION_TYPES from './category.types';

export const setReadyFormCategoryList = (category) => {
  return { type: CATEGORY_ACTION_TYPES.LOAD_READY_FORM_CATEGORIES, payload: category };
};

export const setIPrintCategoryList = (category) => {
  return { type: CATEGORY_ACTION_TYPES.LOAD_I_PRINT_CATEGORIES, payload: category };
};

export const setPaymentServiceCategoryList = (category) => {
  return { type: CATEGORY_ACTION_TYPES.LOAD_PAYMENT_SERVICE_CATEGORIES, payload: category };
};
