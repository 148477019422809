import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LinkForm from "./LinkForm";
import { capitalizeFirstLetter } from "utils/helper";
import { linkUpdate } from "services/api/link";
import Breadcrumb from "ui-component/Breadcrumb";
import { selectLink } from "store/link/link.selector";
import SimpleCard from "ui-component/SimpleCard";

const LinkUpdate = () => {
  const title = "link";

  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));
 
  const { linkId } = useParams();

  const linkState = useSelector(selectLink);

  const currentLink = linkState.filter((item) => item.id == linkId);
  currentLink[0]={...currentLink[0],category:currentLink[0].sub_category} 
  const handleSubmit = (state) => { 
    const formData = new FormData();
        formData.append("name", state.name)
        formData.append("category_id", state.sub_category_id)
        state.image && formData.append("image", state.image)
        formData.append("link", state.link)
     

    return linkUpdate(linkId, formData);
  };

  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            { name: capitalizeFirstLetter(title), path: "/" + title },
            { name: "Link Update" },
          ]}
        />
      </Box>
      <SimpleCard title="Link Form">
        <LinkForm
          handleSubmit={handleSubmit}
          tableData={{ ...currentLink[0] }}
        />
      </SimpleCard>
    </Container>
  );
};

export default LinkUpdate;
