import { LoadingButton } from "@mui/lab";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { subCategoryList } from "services/api/sub-category";
import { setIPrintSubCategoryList } from "store/subCategory/subCategory.action";
import { selectSubCategory } from "store/subCategory/subCategory.selector";
import FileInput from "ui-component/FileInput";

const LinkForm = ({ handleSubmit, tableData }) => {
  const {image}=tableData;
  const [state, setState] = useState({ ...tableData,image:null });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const setData = () => {
    subCategoryList("filter[type]=IP")
      .then((res) => { 
        dispatch(setIPrintSubCategoryList(res.data.result.data));

        return res.data.result.data;
      })
      .catch((error) => {
        console.log(error);
        openSnackbar("error", error.data.message);
      });
    console.log(3);
    return [];
  };

  useEffect(() => {
    setData();
  }, []);

  const handleFormSubmit = async (values, { setFieldError }) => {
    setLoading(true);
    await handleSubmit({
      ...values,   
      image: state.image,
    })
      .then((res) => {
        openSnackbar("success", res.data.message);
        setLoading(false);
        navigate(-1);
      })
      .catch((e) => {
        if (e.response.status === 422) {
          console.log(e.response.data.result);
          Object.entries(e.response.data.result).forEach(([key, value]) =>
            setFieldError(key, value)
          );
        }
        setLoading(false);
      });
  };
  const handleFileChange = (index, file) => { 
    setState({ ...state, image: file.files[0] });
  };
  const { iPrintSubCategories } = useSelector(selectSubCategory);
  const category = iPrintSubCategories;
  return (
    <div>
      <Formik onSubmit={handleFormSubmit} initialValues={state}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="name"
                  id="name"
                  value={values.name || ""}
                  onChange={handleChange}
                  label="Name"
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <Autocomplete
                  options={category}
                  disableClearable
                  defaultValue={state.category}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setFieldValue("category_id", newValue.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  id="controllable-category-state"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub Category Name"
                      name="category"
                      value={state.category_id || ""}
                      variant="outlined"
                      onChange={handleChange}
                      helperText={touched.category_id && errors.category_id}
                      error={Boolean(touched.category_id && errors.category_id)}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <FileInput
                  url={image}
                  handleChange={handleFileChange}
                  fileName="image"
                  label="Image"
                  helperText={touched.image && errors.image}
                  error={Boolean(touched.image && errors.image)}
                />
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="link"
                  id="link"
                  value={values.link || ""}
                  onChange={handleChange}
                  label="Link"
                  helperText={touched.link && errors.link}
                  error={Boolean(touched.link && errors.link)}
                  fullWidth
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              color="primary"
              loading={loading}
              variant="contained"
              sx={{ my: 4 }}
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LinkForm;
