import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import AdminRoutes from 'views/admin/AdminRoutes';
import CategoryRoutes from 'views/category/CategoryRoutes';
import SubCategoryRoutes from 'views/sub-category/SubCategoryRoutes';
import ReadyFormRoutes from 'views/ready-form/ReadyFormRoutes';
import LinkRoutes from 'views/link/LinkRoutes';
import DynamicFormRoutes from 'views/dynamic-form/DynamicFormRoutes';
import SettingRoutes from 'views/setting/SettingRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, AuthenticationRoutes,AdminRoutes,SubCategoryRoutes, CategoryRoutes,ReadyFormRoutes,LinkRoutes,DynamicFormRoutes,SettingRoutes]);
}
