import { styled } from "@mui/material";
import { Box } from "@mui/system";
import ReadyFormForm from "./ReadyFormForm";
import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import {  readyFormUpdate } from "services/api/ready-form";
import SimpleCard from "ui-component/SimpleCard"; 
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectReadyForm } from "store/readyForm/readyForm.selector";
const ReadyFormUpdate = () => {
    const title = 'ReadyForm';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));
    const { formId } = useParams();

  
     const readyForm = useSelector(selectReadyForm);
     const currentReadyForm = readyForm.filter((item) => item.id == formId);
    currentReadyForm[0]={...currentReadyForm[0],category:currentReadyForm[0].category} 
    const handleSubmit = (state) => { 
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("category_id", state.category_id)
        state.file && formData.append("file", state.file)
        formData.append("price_color", state.price_color)
        formData.append("price_black_and_white", state.price_black_and_white)
        
        return readyFormUpdate(formId,formData);
    };


    return ( 
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "ReadyForm Create" }]} />
            </Box>
             <SimpleCard title="ReadyForm Form">
                 <ReadyFormForm handleSubmit={handleSubmit} tableData={{...currentReadyForm[0]}}/>
             </SimpleCard>
         </Container>
    );
};  
export default ReadyFormUpdate;