// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const kiosk = {
    id: 'kiosk',
    title: 'Kiosk',
    type: 'group',
    children: [
        {
            id: 'kiosk',
            title: 'Kiosk',
            type: 'item',
            url: '/kiosk',
            icon: icons.IconTypography,
            breadcrumbs: false
        }
        
    ]
};

export default kiosk;
