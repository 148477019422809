import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout";
import Link from "./Link";
import LinkCreate from "./LinkCreate";
import LinkUpdate from "./LinkUpdate";

 

const LinkRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/payment-center/links",
            element:<Link/>
        },
        {
            path:"//payment-center/links/create",
            element:<LinkCreate/>
        },
        {
            path:"//payment-center/links/edit/:linkId",
            element:<LinkUpdate/>
        },
    ]
}

export default LinkRoutes;