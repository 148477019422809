import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout"; 
import SettingUpdate from "./SettingUpdate";

 

const SettingRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/settings",
            element:<SettingUpdate/>
        },
    ]
}

export default SettingRoutes;