import { styled } from "@mui/material";
import { Box } from "@mui/system";
import AdminForm from "./AdminForm";
import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import { adminCreate } from "services/api/admin";
import SimpleCard from "ui-component/SimpleCard";
const AdminCreate = () => {
    const title = 'Admin';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const currentAdmin = { name: "", email: "", password: "" }

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("email", state.email)
        formData.append("password", state.password)
        
        return adminCreate(formData);
    };


    return (
        // <h1>asdasd</h1>
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Admin Create" }]} />
            </Box>
             <SimpleCard title="Admin Form">
                 <AdminForm handleSubmit={handleSubmit} tableData={currentAdmin} />
             </SimpleCard>
         </Container>
    );
};  
export default AdminCreate;