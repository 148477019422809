import axiosClient from "services/axios"

export const adminList=()=> axiosClient.get('admins');
export const adminCreate=(data)=> axiosClient.post('admins',data);
export const adminUpdate = (id, data) => axiosClient.put(`admins/${id}`, data, {
    headers: {
        'Content-Type': 'application/json',
    }
});
export const adminDelete=(id)=> axiosClient.delete(`admins/${id}`);
export const adminUpdateStatus=(id)=> axiosClient.get(`admins-status/${id}`);