import SUBCATEGORY_ACTION_TYPES from './subCategory.types';

export const SUBCATEGORY_INITIAL_STATE = { 
  readyFormSubCategories: [],
  iPrintSubCategories: [],
  paymentServiceSubCategories: [],
};

export const SubCategoryReducer = (state = SUBCATEGORY_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) { 
    case SUBCATEGORY_ACTION_TYPES.LOAD_READY_FORM_SUBCATEGORIES: {
      return { ...state, readyFormSubCategories: payload };
    }
    case SUBCATEGORY_ACTION_TYPES.LOAD_I_PRINT_SUBCATEGORIES: {
      return { ...state, iPrintSubCategories: payload };
    }
    case SUBCATEGORY_ACTION_TYPES.LOAD_PAYMENT_SERVICE_SUBCATEGORIES: {
      return { ...state, paymentServiceSubCategories: payload };
    }
    default:
      return state;
  }
};
