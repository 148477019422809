import { IconUser } from '@tabler/icons';

const user_management={
 id:'user_management',
 title:'User    ',
 type:'group',
 children:[
     {
         id:'admin',
         title:'Admin',
         type:'item',
         url:'/admins',
         icon:IconUser,
    }
 ]
}

export default user_management;