import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SubCategoryForm from "./SubCategoryForm";
import { capitalizeFirstLetter } from "utils/helper";
import { subCategoryUpdate } from "services/api/sub-category";
import Breadcrumb from "ui-component/Breadcrumb";
import { selectSubCategory } from "store/subCategory/subCategory.selector";
import SimpleCard from "ui-component/SimpleCard";

const SubCategoryUpdate = ({type}) => {
  const title = "subcategory";

  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const dispatch = useDispatch();
  const { subCategoryId } = useParams();

  const { readyFormSubCategories, iPrintSubCategories, paymentServiceSubCategories } = useSelector(selectSubCategory);

  let currentSubCategory = [];
   if (type == 'RF') {
    currentSubCategory = readyFormSubCategories.filter((item) => item.id == subCategoryId);
    
  } else if (type == 'IP') {
    currentSubCategory = iPrintSubCategories.filter((item) => item.id == subCategoryId);
  } else if (type == 'PS') {
    currentSubCategory = paymentServiceSubCategories.filter((item) => item.id == subCategoryId);
  }
  currentSubCategory[0]={...currentSubCategory[0],category:currentSubCategory[0].category} 
  console.log(currentSubCategory);
  const handleSubmit = (state) => {
    console.log(state);
    const formData = new FormData();
    formData.append("name", state.name)
    state.image && formData.append("image", state.image)
    formData.append("type", state.type)
    formData.append("parent_id", state.parent_id)
     
    return subCategoryUpdate(subCategoryId, formData);
  }; 
  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            { name: capitalizeFirstLetter(title), path: "/" + title },
            { name: "SubCategory Update" },
          ]}
        />
      </Box>
      <SimpleCard title="SubCategory Form">
        <SubCategoryForm
          handleSubmit={handleSubmit}
          tableData={{ ...currentSubCategory[0] }}
          type={type}
        />
      </SimpleCard>
    </Container>
  );
};

export default SubCategoryUpdate;
