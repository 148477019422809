import SETTING_ACTION_TYPES from './setting.types';

export const SETTING_INITIAL_STATE = {
  settingLists: [],
};

export const SettingReducer = (state = SETTING_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SETTING_ACTION_TYPES.LOAD_SETTINGS: {
      return { ...state, settingLists: payload };
    }
    default:
      return state;
  }
};
