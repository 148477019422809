import { styled } from "@mui/material";
import { Box } from "@mui/system";
import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import SimpleCard from "ui-component/SimpleCard";
import { categoryCreate } from "services/api/category";
import CategoryForm from "./CategoryForm";
const CategoryCreate = ({ type }) => {

    let url = "categories";
    let title = "Categories";
    if (type == 'RF') {
        url = 'ready-form/categories';
        title = 'Ready Form Categories';
    } else if (type == 'IP') {
        url = 'i-print/categories';
        title = 'I Print Categories';
    } else if (type == 'PS') {
        url = 'payment-center/categories';
        url = 'Payment Center Categories';
    }

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const currentCategory = { name: "", image: "" }

    const handleSubmit = (state) => {
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("image", state.image)
        formData.append("type", type)

        return categoryCreate(formData);
    };


    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + url }, { name: `${title} Create` }]} />
            </Box>
            <SimpleCard title="Category Form">
                <CategoryForm type={url} handleSubmit={handleSubmit} tableData={currentCategory} />
            </SimpleCard>
        </Container>
    );
};
export default CategoryCreate;