import { LoadingButton } from "@mui/lab";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"; 
import {
  setReadyFormCategoryList,
} from "store/category/category.action";
import FileInput from "ui-component/FileInput";
import { selectSubCategory } from "store/subCategory/subCategory.selector";
import { subCategoryList } from "services/api/sub-category";
const ReadyFormForm = ({ handleSubmit, tableData, type }) => {
  const {file}=tableData;
  const [state, setState] = useState({ ...tableData,file:null });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();

  const setData = () => {
    subCategoryList("filter[type]=RF")
      .then((res) => {
        dispatch(setReadyFormCategoryList(res.data.result.data));
        return res.data.result.data;
    })
    .catch((error) => {
        openSnackbar("error", error.data.message);
    });
    console.log(3);
    return [];
  };

  useEffect(() => { 
    setData(); 
  }, []); 
  const handleFormSubmit = async (values, { setFieldError }) => {
    setLoading(true);
    await handleSubmit({
      ...values,
      file: state.file,
    })
      .then((res) => {
        openSnackbar("success", res.data.message);
        setLoading(false);
        navigate(-1);
      })
      .catch((e) => {
        if (e.response.status === 422) {
          console.log(e.response.data.result);
          Object.entries(e.response.data.result).forEach(([key, value]) =>
            setFieldError(key, value)
          );
        }
        setLoading(false);
      });
  };
  const handleFileChange = (index, file) => {
    setState({ ...state, file: file?.files[0] });
    console.log(state);
  };
  
  const { readyFormSubCategories } = useSelector(selectSubCategory);
  const category=readyFormSubCategories;
  console.log(readyFormSubCategories);
  console.log(category);
  return (
    <div>
      <Formik onSubmit={handleFormSubmit} initialValues={state}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="name"
                  id="name"
                  value={values.name || ""}
                  onChange={handleChange}
                  label="Name"
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <Autocomplete
                  options={category}
                  disableClearable
                  defaultValue={state.category}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    setFieldValue("category_id", newValue.id);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  id="controllable-category-state"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sub Category Name"
                      name="category"
                      value={state.category || ""}
                      variant="outlined"
                      onChange={handleChange}
                      helperText={touched.parent_id && errors.parent_id}
                      error={Boolean(touched.parent_id && errors.parent_id)}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="price_color"
                  id="price_color"
                  value={values.price_color || ""}
                  onChange={handleChange}
                  label="Price Color"
                  helperText={touched.price_color && errors.price_color}
                  error={Boolean(touched.price_color && errors.price_color)}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <TextField
                  type="text"
                  name="price_black_and_white"
                  id="price_black_and_white"
                  value={values.price_black_and_white || ""}
                  onChange={handleChange}
                  label="Price Black & White"
                  helperText={
                    touched.price_black_and_white &&
                    errors.price_black_and_white
                  }
                  error={Boolean(
                    touched.price_black_and_white &&
                      errors.price_black_and_white
                  )}
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                <FileInput
                  name="image"
                  url={file}
                  handleChange={handleFileChange}
                  fileName="image"
                  label="File"
                  assets=".pdf"
                  helperText={touched.image && errors.image}
                  error={Boolean(touched.image && errors.image)}
                />
              </Grid>
            </Grid>

            <LoadingButton
              type="submit"
              color="primary"
              loading={loading}
              variant="contained"
              sx={{ my: 4 }}
            >
              Submit
            </LoadingButton>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ReadyFormForm;
