import { createSelector } from "reselect";

const selectSubCategoryReducer = (state) => {
  return state.subCategory
};

export const selectSubCategory = createSelector(
  [selectSubCategoryReducer],
  (state) => {
    return state
  }
);