import dashboard from './dashboard';
import ready_form from './ready_form';
import i_print from './i_print';
import payment_center from './payment_center';
import kiosk from './kiosk';
import print_history from './print_history';
import user_management from './user_management';
import setting from './setting';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard,user_management, kiosk, ready_form, i_print, payment_center, print_history,setting]
};

export default menuItems;
