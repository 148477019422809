import AuthGuard from "auth/AuthGuard";
import MainLayout from "layout/MainLayout"; 
import DynamicForm from "./DynamicForm";
import DynamicFormCreate from "./DynamicFormCreate";
import DynamicFormUpdate from "./DynamicFormUpdate";
import DynamincFormFieldUpdate from "./DynamicFormFieldUpdate";

 

const DynamicFormRoutes={
    path:"/",
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:"/i-print/dynamic-forms",
            element:<DynamicForm/>
        },
        {
            path:"/i-print/dynamic-forms/create",
            element:<DynamicFormCreate/>
        },
        {
            path:"/i-print/dynamic-forms/edit/:formId",
            element:<DynamicFormUpdate/>
        },
        {
            path:"/i-print/dynamic-forms/field-edit/:formId",
            element:<DynamincFormFieldUpdate/>
        },
    ]
}

export default DynamicFormRoutes;