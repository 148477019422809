import { styled } from "@mui/material";
import { Box } from "@mui/system";
import DynamicFormForm from "./DynamicFormForm";
import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper";
import {  dynamicFormUpdate } from "services/api/dynamic-form";
import SimpleCard from "ui-component/SimpleCard"; 
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { selectDynamicForm } from "store/dynamicForm/dynamicForm.selector";
const DynamicFormUpdate = () => {
    const title = 'DynamicForm';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));
    const { formId } = useParams();

  
     const dynamicForm = useSelector(selectDynamicForm);    
     console.log(dynamicForm);
     const currentDynamicForm = dynamicForm.filter((item) => item.id == formId);
     console.log(formId);
     console.log(currentDynamicForm);
    currentDynamicForm[0]={...currentDynamicForm[0],category:currentDynamicForm[0].sub_category} 
    const handleSubmit = (state) => { 
        const formData = new FormData();
        formData.append("name", state.name)
        formData.append("category_id", state.sub_category_id)
        state.file && formData.append("file", state.file)
        formData.append("price_color", state.price_color)
        formData.append("price_black_and_white", state.price_black_and_white)
        
        return dynamicFormUpdate(formId,formData);
    };
console.log(currentDynamicForm);

    return ( 
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "DynamicForm Create" }]} />
            </Box>
             <SimpleCard title="DynamicForm Form">
                 <DynamicFormForm handleSubmit={handleSubmit} tableData={{...currentDynamicForm[0]}}/>
             </SimpleCard>
         </Container>
    );
};  
export default DynamicFormUpdate;