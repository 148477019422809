import { LoadingButton } from "@mui/lab";
import {
    Grid,
    TextField,
} from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


const SettingForm = ({ handleSubmit, tableData }) => {
    const [state, setState] = useState({...tableData});

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbar();
 

    const handleFormSubmit = async (values, { setFieldError }) => {
        setLoading(true);
        await handleSubmit(values).then((res) => {
            openSnackbar("success", res.data.message)
            setLoading(false);
            navigate("/settings");
        }).catch((e) => {
            if (e.response.status == 422) {
                console.log(e.response.data.result);
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value)

                );

            }
            setLoading(false);
        });
    }; 
    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={state}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="price_color"
                                    id="price_color"
                                    value={values.price_color || ""}
                                    onChange={handleChange}
                                    label="Print Color"
                                    helperText={touched.price_color && errors.price_color}
                                    error={Boolean(touched.price_color && errors.price_color)}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    type="price_black_and_white"
                                    name="price_black_and_white"
                                    id="price_black_and_white"
                                    value={values.price_black_and_white}
                                    onChange={handleChange}
                                    helperText={touched.price_black_and_white && errors.price_black_and_white}
                                    error={Boolean(touched.price_black_and_white && errors.price_black_and_white)}
                                    label="Print Black & White"
                                    fullWidth
                                />
                            </Grid>

 
                        </Grid>


                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={loading}
                            variant="contained"
                            sx={{ my: 4 }}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default SettingForm;
