import LINK_ACTION_TYPES from './link.types';

export const LINK_INITIAL_STATE = {
  linkLists: [],
};

export const LinkReducer = (state = LINK_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LINK_ACTION_TYPES.LOAD_LINKS: {
      return { ...state, linkLists: payload };
    }
    default:
      return state;
  }
};
