import { createSelector } from "reselect";

const selectReadyFormReducer = (state) => {
  return state.readyForm.readyFormLists
};

export const selectReadyForm = createSelector(
  [selectReadyFormReducer],
  (state) => {
    return state
  }
);